import Perk from '@/models/Perk'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    perkDetails: null,
    totalPerks: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setPerkList(state, perks) {
      state.list = perks
    },

    clearPerkList(state) {
      state.list = []
      state.totalPerks = 0
    },

    setPerkListMeta(state, meta) {
      state.listMeta = meta
    },

    setPerkDetails(state, perk) {
      if (state.perkDetails) {
        if (!perk.icon) perk.icon = state.perkDetails.icon
        if (!perk.banner) perk.banner = state.perkDetails.banner
        if (!perk.thumbnail_banner)
          perk.thumbnail_banner = state.perkDetails.thumbnail_banner

        if (!perk.likes_count) perk.likes_count = state.perkDetails.likes_count
        if (!perk.dislikes_count)
          perk.dislikes_count = state.perkDetails.dislikes_count
      }

      if (perk instanceof Perk) {
        state.perkDetails = perk
      } else {
        state.perkDetails = new Perk(perk)
      }
    },

    setPerkImage(state, payload) {
      if (state.perkDetails) {
        state.perkDetails[payload.image_name] = payload.image
      }
    },

    clearPerkDetails(state) {
      state.perkDetails = null
    },

    setPerkTotals(state, total) {
      state.totalPerks = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getPerks({ commit, rootGetters }, params) {
      const query = Perk.page(params.page || 1).limit(params.itemsPerPage || 10)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      switch (params.filter) {
        case 'active':
          query.where('is_active', 1)
          break
        case 'inactive':
          query.where('is_active', 0)
          break
        default:
          break
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include(['buildings'])

      const res = await query.get()
      commit('setPerkList', res.data)
      commit('setPerkListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setPerkTotals', get(res, 'meta.total', 0))
      }
    },

    getPerkByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getPerkDetails({ commit }, id) {
      const { data } = await Api.get(`perks/${id}`)
      commit('setPerkDetails', data.data)
    },

    async createPerk({ commit }, formData) {
      const { data } = await Api.post(`perks`, formData)
      commit('setPerkDetails', data.data)
    },

    async updatePerk({ commit }, formData) {
      const { data } = await Api.put(`perks/${formData.id}`, formData)
      commit('setPerkDetails', data.data)
    },

    async uploadPerkImage({ commit }, formData) {
      const { data } = await Api.post(
        `perks/${formData.get('id')}/images`,
        formData
      )

      commit('setPerkImage', {
        image: data.data,
        image_name: formData.get('image_name'),
      })
    },

    async deletePerkImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)
      commit('setPerkImage', {
        image: null,
        image_name: formData.image_name,
      })
    },
  },
}
