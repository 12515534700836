import Urgency from '@/models/Urgency'
import Api from '@/services/api'
import { each, find } from 'lodash'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    urgencyDetails: null,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setUrgencyList(state, urgencies) {
      each(urgencies, (urgency) => {
        const exist = find(state.list, { id: urgency.id })
        if (!exist) {
          state.list.push(new Urgency(urgency))
        }
      })
    },

    clearUrgencyList(state) {
      state.list = []
    },

    setUrgencyListMeta(state, meta) {
      state.listMeta = meta
    },

    setUrgencyDetails(state, urgency) {
      if (urgency instanceof Urgency) {
        state.urgencyDetails = urgency
      } else {
        state.urgencyDetails = new Urgency(urgency)
      }
    },

    clearUrgencyDetails(state) {
      state.urgencyDetails = null
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getUrgencies({ commit }, params) {
      let res = null

      if (params) {
        const query = Urgency.page(params.page || 1).limit(
          params.itemsPerPage || 30
        )
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        query.where('type', CATEGORY_TYPE.urgency)
        res = await query.get()

        commit('setUrgencyList', res.data)
        commit('setUrgencyListMeta', res.meta)
      } else {
        res = await Urgency.where('type', CATEGORY_TYPE.urgency)
          .params({ paginate: false })
          .get()

        return res.data
      }
    },

    async getUrgencyDetails({ commit }, id) {
      const { data } = await Api.get(`categories/${id}`)
      commit('setUrgencyDetails', data.data)
    },

    async createUrgency({ commit }, formData) {
      formData.type = CATEGORY_TYPE.urgency
      const { data } = await Api.post(`categories`, formData)
      commit('setUrgencyDetails', data.data)
    },

    async updateUrgency({ commit }, formData) {
      formData.type = CATEGORY_TYPE.urgency
      const { data } = await Api.put(`categories/${formData.id}`, formData)
      commit('setUrgencyDetails', data.data)
    },
  },
}
