import Folder from '@/models/Folder'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    folderDetails: null,
    totalFolders: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setFolderList(state, folders) {
      state.list = folders
    },

    clearFolderList(state) {
      state.list = []
    },

    setFolderDetails(state, folder) {
      if (folder instanceof Folder) {
        state.folderDetails = folder
      } else {
        state.folderDetails = new Folder(folder)
      }
    },

    clearFolderDetails(state) {
      state.folderDetails = null
    },

    setTotalFolders(state, total) {
      state.totalFolders = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getFolders({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Folder.page(params.page || 1).limit(
          params.itemsPerPage || 25
        )
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['documents', 'documents.file', 'buildings', 'user'])

        res = await query.get()
      } else {
        res = await Folder.params({ paginate: false }).get()
      }

      commit('setFolderList', get(res, 'data', []))
      commit('setTotalFolders', get(res, 'meta.total', 0))
    },

    async getFolderDetails({ commit }, id) {
      const { data } = await Api.get(`folders/${id}`)
      commit('setFolderDetails', data.data)
    },

    async createFolder({ commit }, formData) {
      const { data } = await Api.post(`folders`, formData)
      commit('setFolderDetails', data.data)
    },

    async updateFolder({ commit }, formData) {
      const { data } = await Api.put(`folders/${formData.id}`, formData)
      commit('setFolderDetails', data.data)
    },
  },
}
